class Contact {
    static init() {
        this.contacts = document.querySelectorAll(".fContact");
        let url = data_api?.url_subscribe_newswletter;
        let keyCaptcha = data_api?.friendly_captcha_site_key;

        if (url && keyCaptcha) {
            this.contacts.forEach(contact => {
                let form = contact.querySelector(".fContact_box")

                if (form) {
                    form.querySelector(".captchaBox").innerHTML = `<div class="frc-captcha" data-lang="fr" data-start="none" name="frc-captcha-solution" data-sitekey="${keyCaptcha}"></div>`
                    form.addEventListener("submit", e => {
                        e.preventDefault();

                        this.resetError(form);

                        let formData = new FormData(form);
                        formData.append("newsletter_optin_rgpd", "1");

                        fetch(url, {
                            method: 'POST',
                            body: formData
                        }).then(response => response.json())
                            .then(data => {
                                console.log(data);
                                if (data.code === "error_validation") {
                                    for (var key in data.data.errors) {
                                        let field = form.querySelector("[name='" + key + "'");
                                        let error = form.querySelector("[data-name='" + key + "'");

                                        if (field) {
                                            field.classList.add("errorField");
                                        }

                                        if (error) {
                                            error.innerHTML = data.data.errors[key];
                                        }
                                    }

                                    friendlyChallenge.autoWidget.reset();
                                } else {
                                    contact.querySelector(".fContact_finish").classList.add("visible");
                                    form.classList.add("hidden");
                                }
                            })

                    })
                }
            })
        }
    }

    static resetError(form) {
        form.querySelectorAll(".fContact_error").forEach(p => p.innerHTML = "");
        form.querySelectorAll(".errorField").forEach(p => p.classList.remove("errorField"));
    }
}

export default Contact;