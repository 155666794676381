import Swiper from 'swiper';
import 'swiper/css';
import { Navigation } from 'swiper/modules';

class Slider {
    static init() {

        let fSlider = document.querySelectorAll(".fSlider");


        fSlider.forEach(fs => {
            let swiperBox = fs.querySelector(".swiper > .swiper-wrapper");
            let items = swiperBox.querySelectorAll(".swiper-slide");

            if (items.length === 4) {
                items.forEach(item => swiperBox.appendChild(item.cloneNode(true)));
            } else if (items.length <= 3) {
                fs.classList.add("noNavDesktop")
            }
        })

        

        const swiper = new Swiper('.swiper', {
            autoHeight: true,
            modules: [Navigation],
            loop: true,
            slidesPerView: 1,

            // Navigation arrows
            navigation: {
                nextEl: '.fSlider_nav--next',
                prevEl: '.fSlider_nav--prev',
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
            },
        });
        swiper.init();
    }
}

export default Slider;