class Soustitrage {
    static init() {
        document.fonts.ready.then((fontFaceSet) => {
            this.items = document.querySelectorAll(".pink-underlined");
            this.items.forEach(item => {
                item.classList.add("soustitrage")
                item.trueContent = item.textContent;
                item.trueContentTab = item.trueContent.split(" ");
            })

            window.addEventListener("resize", () => this.render())
            this.render();

            document.addEventListener("scroll", () => this.checkItems());
            this.checkItems();
        })
    }

    static checkItems() {
        [...this.items].filter(item => !item.alreadyDone).forEach(item => {
            let infos = item.getBoundingClientRect();

            if (infos.top > 0 && infos.bottom < window.innerHeight) {
                item.alreadyDone = true;
                item.classList.add("visible");
            }
        })
    }

    static render() {
        this.items.forEach(item => {
            item.innerHTML = "";

            let lines = [];
            let currentHeight = 0;

            let line = document.createElement("span");
            line.classList.add("soustitrage_item");

            item.trueContentTab.forEach(tab => {
                item.innerHTML += tab + " ";

                let nextHeight = item.getBoundingClientRect().height;
                if (currentHeight != nextHeight) {
                    if (currentHeight != 0) {
                        line.innerHTML = line.innerHTML.trim();
                        lines.push(line);
                        line = document.createElement("span");
                        line.classList.add("soustitrage_item");
                    }

                    currentHeight = nextHeight;
                }

                line.innerHTML += tab + " ";
            })

            line.innerHTML = line.innerHTML.trim();
            lines.push(line);

            item.innerHTML = "";
            lines.forEach(line => {
                item.appendChild(line);
                item.append(document.createTextNode(" "));
            })
        })
    }
}

export default Soustitrage;