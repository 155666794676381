class Faq {
    static init() {
        this.faqs = document.querySelectorAll(".fFaq_liste");
        this.faqs.forEach(faq => {
            faq.items = faq.querySelectorAll(".fFaq_item");
            faq.items.forEach(item => {
                item.classList.add("fFaq_item--close")
                item.btn = item.querySelector(".fFaq_cta");
                item.box = item.querySelector(".fFaq_box");

                item.box.addEventListener("transitionend", e => {
                    if (e.propertyName === "max-height") {
                        console.log(e)
                        item.box.style.maxHeight = "";
                    }
                });

                if (item.btn) {
                    item.btn.faq = faq;
                    item.btn.item = item;
                    item.btn.addEventListener("click", () => this.toggleFaq(item.btn));
                }
            })
        })
    }

    static toggleFaq(btn) {
        let wasOpen = !btn.item.classList.contains("fFaq_item--close");
        btn.faq.items.forEach(item => {
            if (wasOpen || item != btn.item) {
                let height = item.box.getBoundingClientRect().height;
                item.box.style.maxHeight = height + "px";
                item.classList.add("fFaq_item--close")
                setTimeout(() => {
                    item.box.style.maxHeight = "";
                }, 10)
            }
        });
        if (!wasOpen) {
            btn.item.box.style.transition = "none"
            btn.item.classList.remove("fFaq_item--close");
            let height = btn.item.box.getBoundingClientRect().height;
            btn.item.classList.add("fFaq_item--close");
            setTimeout(() => {
                btn.item.box.style.transition = "";
                setTimeout(() => {
                    btn.item.box.style.maxHeight = height + "px";
                    btn.item.classList.remove("fFaq_item--close");
                }, 10)
            }, 10)
        }
    }
}

export default Faq;