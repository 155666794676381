class Layout{
    static init(){
        this.ContactVague = document.querySelectorAll(".center_form_bg_image");

        this.ContactVague.forEach(item => {
            item.previousElementSibling.classList.add("withPaddingBottom");
            item.nextElementSibling.classList.add("withPaddingTop");
        })
    }
}

export default Layout;