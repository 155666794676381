class Vague {
    static init() {
        this.blocs = document.querySelectorAll("[data-vague]");
        this.blocs.forEach(bloc => bloc.visuel = bloc.querySelector(".fContact_visuel"))

        window.addEventListener("resize", () => this.checkVague());
        this.checkVague();

        document.addEventListener("scroll", () => this.checkPosition());
        this.checkPosition()
    }

    static checkPosition() {
        [...this.blocs].filter(block => block.classList.contains("center_form_bg_image") && block.visuel).forEach(block => {
            let coef = 200;
            let top = block.getBoundingClientRect().top;
            let step = top < window.innerHeight ? top / window.innerHeight : 1;
            step = step < 0 ? 0 : step;

            block.visuel.style.top = (coef * step - coef) + "px";
        })
    }

    static checkVague() {
        this.blocs.forEach(bloc => {

            if (bloc.svg) bloc.removeChild(bloc.svg);

            let infos = bloc.getBoundingClientRect();
            let width = infos.width;
            let height = infos.height;
            let ratio = bloc.dataset.vague;
            let heightNeeded = width * ratio;
            let heightPercent = heightNeeded / height;
            let reverseHeightPercent = 1 - heightPercent;

            document.createElementNS
            bloc.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            bloc.svg.setAttribute("width", "0");
            bloc.svg.setAttribute("height", "0");
            bloc.svg.innerHTML = `
               <defs>
                    <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                        <path d="M0,0 C0,0,0.23,${heightPercent},0.5,${heightPercent},0.77,${heightPercent},1,0 1,0 L1,1 C1,1,0.77,${reverseHeightPercent},0.5,${reverseHeightPercent},0.23,${reverseHeightPercent},0,1, 0,1z">
                    </path></clipPath>
                </defs>
            `;

            bloc.appendChild(bloc.svg);
        })
    }
}

export default Vague;