class Popin {

    static init() {
        this.popins = document.querySelectorAll(".popinCta");
        this.html = document.querySelector("html");

        this.popins.forEach(popin => {
            popin.img = popin.querySelector("img").src;
            popin.addEventListener("click", () => this.openPopin(popin))
        })
    }

    static openPopin(popin){
        popin.html = document.createElement("div");
        popin.html.classList.add("popin");

        popin.html.innerHTML = `
            <div class="popin_overlay"></div>
            <div class="popin_close">+</div>
            <div class="popin_box">
                <img src="${popin.img}" alt="" />
            </div>
        `

        popin.html.querySelector(".popin_overlay").addEventListener("click", () => this.closePopin(popin))

        this.html.appendChild(popin.html);
    }

    static closePopin(popin){
        this.html.removeChild(popin.html);
    }

}

export default Popin