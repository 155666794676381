class FadeIn {
    static init() {
        this.tabs = [
            // ".fManifeste > *:not(.fManifeste_visuels)",
            // ".fManifeste_visuels > *",
            ".fContact_infos > *",
            ".fContact_box > *",
            ".fMultiLinks_item > *",
            ".fEvenements_titre",
            ".fEvenements_item > *",
            ".fBlockquote_box > *",
            ".fBlockquote_visuel",
            ".fSlider_titre",
            ".fSlider_item > *",
            ".fFaq_titre",
            ".fFaq_liste > *",
            ".footer_line > *",
            ".fEvenementItem_visuel",
            ".fEvenementItem_infos > *",
            ".fContenus > *",
            ".fListeArticles_item > *",
            ".fArticle_wysiwyg > *",
            ".fArticle_navigationBox > *",
            ".fPartenaire_infos > *",
            ".fAbout_wysiwyg > *",
            ".fTrombinoscope_titre",
            ".fTrombinoscope_item > *",
            ".fFollow > *",
            ".fEvenementDetail_nav > *",
            ".fEvenementDetail_wysiwyg > *",
            ".fWysiwyg > *"
        ]
        this.elems = document.querySelectorAll(this.tabs.join(","));
        this.min = 0.1;
        this.max = 1

        let windowHeight = window.innerHeight;
        this.elems.forEach(elem => {
            let infos = elem.getBoundingClientRect();
            if(infos.top < windowHeight * this.min || infos.top > windowHeight * this.max){
                elem.classList.add("fadeIn")
            }
        })
        this.elems = document.querySelectorAll(".fadeIn");
        
        document.addEventListener("scroll", () => this.checkFadeIn());
        this.checkFadeIn()
    }

    static checkFadeIn(){
        let windowHeight = window.innerHeight;
        [...this.elems].filter(elem => !elem.classList.contains("fadeInVisible")).forEach(elem => {
            let infos = elem.getBoundingClientRect();

            if(infos.top > windowHeight * this.min && infos.top < windowHeight * this.max){
                elem.classList.add("fadeInVisible")
            }
        })
    }
}

export default FadeIn;