import Contact from "./components/Contact";
import FadeIn from "./components/FadeIn";
import Faq from "./components/Faq";
import Header from "./components/Header";
import Layout from "./components/Layout";
import Popin from "./components/Popin";
import Soustitrage from "./components/Soustitrage";
import Vague from "./components/Vague";
import Slider from "./components/slider";

const main = {
    init: function() {
        Layout.init();
        Slider.init();
        Header.init();
        Faq.init();
        Soustitrage.init();
        Vague.init();
        FadeIn.init();
        Contact.init();
        Popin.init();
    }
}

export default main