// ECMA-262, Edition 5, 15.4.4.18
// Référence: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {

    Array.prototype.forEach = function (callback /*, thisArg*/) {

        var T, k;

        if (this == null) {
            throw new TypeError(' this vaut null ou n est pas défini');
        }

        // 1. Soit O le résultat de l'appel à ToObject
        //    auquel on a passé |this| en argument.
        var O = Object(this);

        // 2. Soit lenValue le résultat de l'appel de la méthode 
        //    interne Get sur O avec l'argument "length".
        // 3. Soit len la valeur ToUint32(lenValue).
        var len = O.length >>> 0;

        // 4. Si IsCallable(callback) est false, on lève une TypeError.
        // Voir : http://es5.github.com/#x9.11
        if (typeof callback !== "function") {
            throw new TypeError(callback + ' n est pas une fonction');
        }

        // 5. Si thisArg a été fourni, soit T ce thisArg ;
        //    sinon soit T égal à undefined.
        if (arguments.length > 1) {
            T = arguments[1];
        }

        // 6. Soit k égal à 0
        k = 0;

        // 7. On répète tant que k < len
        while (k < len) {

            var kValue;

            // a. Soit Pk égal ToString(k).
            //   (implicite pour l'opérande gauche de in)
            // b. Soit kPresent le résultat de l'appel de la 
            //    méthode interne HasProperty de O avec l'argument Pk.
            //    Cette étape peut être combinée avec c
            // c. Si kPresent vaut true, alors
            if (k in O) {

                // i. Soit kValue le résultat de l'appel de la 
                //    méthode interne Get de O avec l'argument Pk.
                kValue = O[k];

                // ii. On appelle la méthode interne Call de callback 
                //     avec T comme valeur this et la liste des arguments
                //     qui contient kValue, k, et O.
                callback.call(T, kValue, k, O);
            }
            // d. On augmente k de 1.
            k++;
        }
        // 8. on renvoie undefined
    };
}