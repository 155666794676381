class Header {
    static init() {
        this.html = document.querySelector("html");
        this.header = document.querySelector(".header");
        this.toggleMenu = document.querySelector(".header_toggleMenu");
        this.openLanguage = document.querySelector(".header_language_current");
        this.closeLanguage = document.querySelector(".header_language_back");

        if (this.header) {
            document.addEventListener("scroll", () => this.checkHeader());
            this.checkHeader()
        }

        if(this.toggleMenu){
            this.toggleMenu.addEventListener("click", () => this.switchMenu())
        }

        if(this.openLanguage){
            this.openLanguage.addEventListener("click", () => this.header.classList.add("header--menuLangue"))
        }

        if(this.closeLanguage){
            this.closeLanguage.addEventListener("click", () => this.header.classList.remove("header--menuLangue"))
        }
    }

    static checkHeader() {
        this.header.classList[document.documentElement.scrollTop > 0 ? "add" : "remove"]("sticked");
    }

    static switchMenu(){
        this.html.classList.toggle("html--open");
        this.header.classList.toggle("header--open");
        this.header.classList.remove("header--menuLangue");
    }
}

export default Header;